/* 校园招聘 */
<template>
  <div class="campusForPhone">
    <img src="../assets/images/Campus/u2716_div.png" width="100%" />
    <div class="campus-view1-main">
      <div class="campus-view1-content">
        <div
          v-for="(item, index) in showData"
          :key="index"
          @click="item.show = !item.show"
        >
          <div class="campus-view1-content-item">
            <div style="width: 90%">
              <div class="campus-view1-content-item-title">
                <div style="padding: 20px 10px">
                  <span class="text1">{{ item.title }}</span>
                </div>
                <div style="padding: 0px 10px">
                  <span class="text2" v-if="!item.show"
                    >岗位职责：{{ item.info }}</span
                  >
                  <div class="text2 flex align-items-center" v-else>
                    <img src="../assets/images/Campus/u2741.svg" alt="" />
                    <span class="ml5"
                      >岗位资讯电话：176-6095-8313（王女士）</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 10%">
              <div class="campus-view1-content-item-img">
                <img
                  src="../assets/images/Project/01_u416.svg"
                  v-if="!item.show"
                />
                <img v-else src="../assets/images/Project/01_u417.svg" />
              </div>
            </div>
          </div>
          <div class="campus-view1-content-item-body" v-if="item.show">
            <h4 class="font4">岗位职责</h4>
            <ul>
              <li class="font5" v-for="(duty, index) in item.duty" :key="index">
                {{ duty }}
              </li>
            </ul>
            <h4 class="font4">岗位要求</h4>
            <ul>
              <li
                class="font5"
                v-for="(requirements, index) in item.requirements"
                :key="index"
              >
                {{ requirements }}
              </li>
            </ul>
            <div
              class="go-title"
              v-if="item.show"
              @mouseover="item.show = true"
            >
              <div @click="goBoss(item.address)">立即沟通</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddressForPhone></AddressForPhone>
  </div>
</template>
<script>
import AddressForPhone from "./addressForPhone";
export default {
  name: "campusForPhone",
  components: {
    AddressForPhone,
  },
  data() {
    return {
      active: 0,
      showData: [
        {
          title: "后端开发工程师",
          info: "负责物联网平台后台服务的开发与自测… …",
          show: false,
          duty: [
            "负责物联网平台后台服务的开发与自测；",
            "负责物联网平台前端界面的开发与自测；",
            "负责物联网平台数据的存储和转发；",
            "完成领导布置的其他工作；",
          ],
          requirements: [
            "具有扎实的Java的编程基础，具有良好的编程习惯；",
            "熟悉Spring MVC、Mybatis、SpringBoot等框架；",
            "熟练使用Eclipse或IntelliJ等开发工具；",
            "熟悉各种前端技术HTML5 、CSS、JavaScript等开发语言，了解Ajax、JQeury等框架；",
            "熟悉MySQL或者SQL Server等常用数据库，熟练掌握SQL，熟悉Redis；",
            "熟悉Socket编程、HTTP/TCP/UDP协议等；",
            "熟悉XML/JSON等常见的数据封装；",
          ],
          address:
            "https://www.zhipin.com/job_detail/c34a0f951ef9f23f1nZ53dq-E1BT.html?ka=comp_joblist_12",
        },
        {
          title: "前端开发工程师",
          info: "负责公司前端技术架构设计、项目开发、页面重构及交互逻辑处理… …",
          show: false,
          duty: [
            "负责公司前端技术架构设计、项目开发、页面重构及交互逻辑处理；",
            "解决产品开发中的疑难问题，页面性能优化；",
            "梳理和优化前端开发流程，搭建高效集成的前端开发环境，制定合理有效的开发规范；",
            "开发维护前端基础工具，推动前端团队技术演进，参与规划前端团队发展；",
          ],
          requirements: [
            "本科以上学历，计算机相关专业，三年以上网站前端开发经验；",
            "精通vuejs前端开发框架，掌握angular、react等前沿框架，精通HTML+JS+CSS等前端开发技术；",
            "熟悉webpack，gulp等打包工具的使用和原理；",
            "具有web性能分析和调优能力，了解一定的后端技术；",
            "熟悉常用算法，数据结构，设计模式，具有良好的编码风格；",
            "工作严谨细致，思路清晰，有优秀的分析问题和解决问题的能力，乐于沟通；",
          ],
          address:
            "https://www.zhipin.com/job_detail/a2bc6ece8ce2b6b01nF93tW7FFZZ.html?ka=comp_joblist_10",
        },
        {
          title: "Android开发工程师",
          info: "根据需求设计完成Android客户端的设计、开发、集成、维护以及线上发布的相关工作… …",
          show: false,
          duty: [
            "根据需求设计完成Android客户端的设计、开发、集成、维护以及线上发布的相关工作；",
            "根据产品的需求对Android端进行架构设计；",
            "解决研发过程中遇到的技术难点，并提供技术方案；",
            "进行APP初期的模块测试，对发现的bug进行修改；",
            "将学习和研究到新的移动互联网技术应用到产品中进行创新;",
          ],
          requirements: [
            "熟悉android平台，具备2年以上的android实际开发经验，有良好的Java技术功底，能根据产品设计独立进行应用程序开发；",
            "熟练使用Android studio、Eclipse、AndroidSDK、SVN 等开发工具和SCM工具；",
            "熟练掌握Android各种UI布局和控件，能够熟练自定义UI控件,熟悉各个屏幕的适配；",
            "熟悉MVC设计模式，编程时注重软件结构设计，熟悉面向对象编程，图形界面开发；",
            "掌握SVN、GIT等代码管理工具；",
            "精通网络异步应用的开发模式, 熟悉网络通信机制，对Socket、TCP和HTTP有较深刻的理解和经验，了解3G/WiFi技术；",
            "有良好的编程习惯，代码结构清晰，命名规范；思路清晰，善于思考，学习能力强，能独立分析和解决问题；",
            "良好的沟通能力和解决问题的能力，思维敏捷，思路清晰，工作积极主动；",
            "具有IOS开发能力优先考虑。",
          ],
          address:
            "https://www.zhipin.com/job_detail/3aa9a0d1dd9a4cb81nV52dS8FlBS.html?ka=comp_joblist_5",
        },
        {
          title: "C++工程师",
          info: "根据公司制定的研发计划进行产品应用软件的开发、软件调试及维护… …",
          show: false,
          duty: [
            "根据公司制定的研发计划进行产品应用软件的开发、软件调试及维护；",
            "产品开发前期及过程中参与产品开发方案的制定；",
            "产品开发完成导入生产前需制定好测试方案及工具；",
            "主动分析并解决开发过程中的问题提高软件代码质量；",
          ],
          requirements: [
            "本科以上学历；",
            "熟练使用C语言编程；",
            "精通STM32 等MCU的开发；",
            "熟悉模电知识、熟悉硬件电路板故障排除，能看懂原理图；",
            "熟悉数字电路和模拟电路设计，熟悉至少一种原理图设计软件的使用；",
            "熟悉uCOS,uCGUI优先；",
          ],
          address:
            "https://www.zhipin.com/job_detail/81be974af42242a61nBz3NW-F1ZT.html?ka=comp_joblist_4",
        },
        {
          title: "大数据分析师",
          info: " 公司业务相关的大数据挖掘、分析",
          show: false,
          duty: [
            "公司业务相关的大数据挖掘、分析；",
            "挖掘数据分析相关指标，对关键的指标监控和分析，对异常数据和风险问题进行预警、解读和挖掘；",
            "建立基于大数据的分析模型，并通过数据分析定位问题，提出解决方案，为业务提供数据决策和数据支持；",
            "设计支持业务决策和运营的数据产品，协调业务方持续改善数据产品功能，提高决策效率；",
            "大数据平台部分算法核心程序研发;",
          ],
          requirements: [
            "硕士及以上学历，计算机、统计学、数学等相关专业，全日制本科有丰富经验者亦考虑；",
            "具有数据清洗、挖掘、分析相关经验；",
            "3年以上Java开发经验；",
            "具备较强的数据驱动思维和数据分析能力，擅于从数据中发现问题和有价值的规律；",
            "熟悉SQL，自我驱动能力强，具备优秀的逻辑思维、需求管理能力；",
            "学习能力强，具备良好的问题分析和解决能力，愿意并致力于新技术研究；",
          ],
          address:
            "https://www.zhipin.com/job_detail/1ee44741095e3c921nJ52t61EVJQ.html?ka=comp_joblist_1",
        },
        {
          title: "嵌入式工程师",
          info: "根据公司制定的研发计划进行产品应用软件的开发、软件调试及维护… …",
          show: false,
          duty: [
            "根据公司制定的研发计划进行产品应用软件的开发、软件调试及维护；",
            "负责产品软件需求分析和代码编写；",
            "负责开发说明文档编写、源码开发及版本控制；",
            "产品开发前期及过程中参与产品开发方案的制定；",
            "产品开发完成导入生产前需制定好测试方案及工具;",
          ],
          requirements: [
            "本科以上学历；",
            "熟练使用C语言编程；",
            "精通STM32 等MCU的开发；",
            "熟悉模电知识、熟悉硬件电路板故障排除，能看懂原理图；",
            "熟悉数字电路和模拟电路设计，熟悉至少一种原理图设计软件的使用；",
            "熟悉uCOS,uCGUI优先；",
          ],
          address:
            "https://www.zhipin.com/job_detail/f65e0edfb1abf20d1nV52dS-GVJR.html?ka=comp_joblist_4",
        },
        {
          title: "总账会计",
          info: "负责公司总账账务处理，财务报表出具及相关分析工作… …",
          show: false,
          duty: [
            "负责公司总账账务处理，财务报表出具及相关分析工作；",
            "负责相关公司纳税筹划，税负控制，自动化研发费归集、比例控制和高新收入归集；",
            "负责公司应付管理、成本核算；",
            "负责整理并管理好涉及财务内容的档案等；",
            "为企业预算编制及管理提供财务数据，为统计人员提供相关财务数据;",
          ],
          requirements: [
            "会计、财务、审计或相关专业本科含以上学历；",
            "敬业、责任心强、严谨踏实、工作仔细认真；",
            "有良好的纪律性、团队合作以及开拓创新精神；",
            "3年以上总账工作经验，有初级会计师以上职称；",
            "了解税务知识，能合理、合法进行税收筹划，熟悉报税流程办理税务申报工作；",
            "有高新技术企业财务工作经验优先，对数据敏感、有较强的沟通能力和学习能力；",
          ],
          address:
            "https://www.zhipin.com/job_detail/86ef2aa0fce6f99d1nN-2NS7GVVR.html?ka=comp_joblist_11",
        },
      ],
    };
  },

  methods: {
    getShow(val) {
      if (this.active == 0) {
        return true;
      } else {
        return this.active == val;
      }
    },
    goBoss(address) {
      window.open(address);
    },
  },
};
</script>
<style lang="scss" scoped>
.campus-view1-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .campus-view1-content {
    width: 94%;

    .campus-view1-content-item {
      background: rgba(247, 247, 247, 1);
      height: 16vh;
      margin: 20px 0px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .campus-view1-content-item-title {
        height: 16vh;
        .text1 {
          font-size: 16px;
          font-family: "PingFangSC-Semibold", "PingFang SC Semibold",
            "PingFang SC", sans-serif;
          font-weight: 650;
          font-style: normal;
        }

        .text2 {
          font-size: 12px;
          font-family: "PingFangSC-Semibold", "PingFang SC Semibold",
            "PingFang SC", sans-serif;
          font-style: normal;
          img {
            width: 15px;
          }
        }
      }

      .campus-view1-content-item-img {
        height: 16vh;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 10px;
        }
      }
    }

    .campus-view1-content-item-body {
      background: rgba(247, 247, 247, 1);
      padding: 10px;
      margin-top: -18px;
      font-size: 14px;
      li {
        padding: 8px 0;
      }
    }
  }

  .go-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    div {
      padding: 10px;
      background: #2997ff;
      color: #ffffff;
      border-radius: 5px;
    }
  }
}
.font4 {
  font-size: 14px;
}
.font5 {
  font-size: 12px;
}
</style>
